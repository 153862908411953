.top-bar-categories{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    overflow-x: scroll;
    position: relative;
    padding-left: 25px;
    padding-right: 20px;
    scroll-behavior: smooth;
}

.top-bar-categories .left{
    position: absolute;
    left:0px;
    color: var(--app-color);
    background-color: #ffffff;
    height: 40px;
    width: 25px;
    display: flex;
    align-items: center;
    font-size: 20px;
    border-radius: 5px;
    

}
.top-bar-categories .right{
    position: absolute !important;
    right:0px;
    color: var(--app-color);
    background-color: #ffffff;
    height:40px ;
    width: 25px;
    display: flex;
    align-items: center;
    font-size: 20px;
    border-radius: 5px;
}
.top-bar-categories::-webkit-scrollbar{
    display: none;
}

.top-bar-categories .top-bar-category{
    display: flex;
    flex: 0 0 auto;
    background-color: whitesmoke;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    height: 30px;
    border-radius: 15px;
    align-items: center;
    cursor:pointer;
    transition: border .5s ease-in-out;
}

.isActive{
    border: 2px solid var(--app-color);
    color: var(--app-color);
}

.top-bar-categories .categories{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.top-bar-categories .categories::-webkit-scrollbar{
    display: none;
}
@media(min-width:200px){
    .top-bar-categories .left{
        left:0px;
    }
    .top-bar-categories .right{
        right:0px;
    }
}
@media(min-width:768px){
    .top-bar-categories .left{
        left:0%;
    }
    .top-bar-categories .right{
        right:0%;
    }
}