.horizontal-banner-product{
    width: 150px;
    height: 260px;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    color: #000000;
    word-wrap: break-word;
    flex: 0 0 auto; 
    transition: box-shadow .5s ease-in-out;   
}

.horizontal-banner-product:hover{
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.18);
}

.horizontal-banner-product .banner-image{
    height: 60%;
    width: 100%;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
    border-radius: 5px;
    position: relative;
}

.horizontal-banner-product .banner-image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.horizontal-banner-product .banner-image .default{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    position: absolute;
    z-index: 8;
    display:flex;
    top: 0%;
}

.horizontal-banner-product .description{
    display: flex;
    flex-direction: column;
    position: relative;
    height: 30%;
}

.horizontal-banner-product .description .productName{
    font-size: var(--default-font-size);
    margin-left: 8px;
    line-height: 16px;
    margin-top: 0px;
}
.horizontal-banner-product .description .productShop{
    font-size: var(--smallest-font-size);
    margin-left: 8px;
    color: #b9b4b4;
}
.horizontal-banner-product .description .productPrice{
    font-size: var(--small-font-size);
    margin-left: 8px;
    color: var(--app-color);
}

.horizontal-banner-product .description .phoneNumber{
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #29eb33;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

@media(min-width:200px){
    .horizontal-banner-product{
        width: 180px;      
    }
}

@media(min-width:768px){
    .horizontal-banner-product{
        width: 180px;      
    }
}