.title{
    font-size: 25px;
    margin-top: 110px;
    font-weight: bolder;

}

@media(min-width:200px){
    .title{
        display: none;
    }
}

@media(min-width:768px){
    .title{
        display: inline-block;
    }
}