.body{
    width: 100%;
    background-color: #f5f5f5;
    position: relative;
    height: 100vh;
}
.body .wrapper{
    width: 90%;
}
.body .wrapper .shop-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
}

.body .wrapper .shop-container .shop-info{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: fit-content;
    padding-bottom: 20px;
    padding: 1%;
    border-radius: 5px;
    border: 1px grey;
    box-shadow: 2px 2px 2px grey;
    max-height: 90%;
    align-items: center;
}

.body .wrapper .shop-container .shop-info .shop-logo{
    width:80px;
    height: 80px;
    border-radius: 40px;
    object-fit: cover;
}

.body .wrapper .shop-container .shop-info .shop-name{
    font-size: 20px;
    font-weight: 400;
    margin-top: 10px;
}

.body .wrapper .shop-container .shop-info .shop-location{
    font-size: 13px;
    font-weight: 500;
    margin-top: 10px;
}

.body .wrapper .shop-container .shop-info .shop-url{
    width: 80%;
    height: 60px;
    text-align: center;
    border-radius: 30px;
    border: 1px #696666;
    outline: none;
    font-weight: 500;
    font-size: 13px;
    margin-top: 10px;
}

.body .wrapper .shop-container .shop-info .shop-socials{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: fit-content;
    margin-top: 20px;
}

.body .wrapper .shop-container .shop-info .shop-member-since{
    font-size: 20px;
    font-weight: 400;
    margin-top: 10px;
}

.body .wrapper .shop-container .shop-info .shop-contacts-buttons{
    width: fit-content;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
}


.body .wrapper .shop-container .shop-data{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.body .wrapper .shop-container .shop-data .top{
    display: flex;
    flex-direction: column;
}

.body .wrapper .shop-container .shop-data .top .div1{
    display: flex;
    flex-direction: row;
    width:100%;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #d0f5f5;
    height: 70px;
    border-radius: 35px;
}

.body .wrapper .shop-container .shop-data .top .div2{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 5px;
    margin-bottom: 10px;
}

.body .wrapper .shop-container .shop-data .shop-products{
    display: grid;
    grid-column: auto;
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
    
}

.body .wrapper .shop-container .shop-data .shop-products .shop-product{
    display: flex;
    flex-direction: column;
    height: 300px;
    border-radius: 5px;
    transition: all .5s ease-in-out;
}

.body .wrapper .shop-container .shop-data .shop-products .shop-product:hover{
    box-shadow: 2px 2px 2px grey;
    transform: scale(1.02);
}

.body .wrapper .shop-container .shop-data .shop-products .shop-product .image{
    width:100%;
    height: 65%;
    object-fit: cover;
    border-radius: 5px;
}

.body .wrapper .shop-container .shop-data .shop-products .shop-product .product-info{
    margin-top: 2%;
    width:100%;
    background-color: red;
}

.body .wrapper .shop-container .shop-data .shop-products .shop-product .info .price-and-contact{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media(min-width:200px){
    .body .wrapper{
        width: 100%;
        padding: 0%;
    }

    .body .wrapper .shop-container{
        flex-direction: column;
    }

    .body .wrapper .shop-container .shop-info{
        width: 100%;
    }
}

@media(min-width:768px){
    .body .wrapper{
        width: 90%;
        padding: 0%;
    }
    .body .wrapper .shop-container{
        flex-direction: row;
    }
    .body .wrapper .shop-container .shop-info{
        width: 50%;
    }
}