.profile-body{
    margin-top:75px;
    position: relative;
}

.edit-icon{
    position:absolute;
    right:10px;
    top:10px;
    width: 30px;
}

.body-wrapper .profile_bg{
    margin-top:100px;
    position: relative;
}

.body-wrapper .profile_bg::after{
    content: " ";
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    display: block;
}

.body-wrapper .profile_bg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.body-wrapper .user-profile{
     width:100%;
     display:flex;
     flex-direction:row;
     gap:10px;
     margin-top: -20px;
     padding:4px;
     position:relative;
     height: 80px;
     align-items: center;
     
     
}



.body-wrapper .user-profile .profile-image{
    display: flex;
    position: relative;
    width: 80px;
    height: 100%;
    margin-left: 2%;
}

.body-wrapper .user-profile .profile-image .select-file{
    width: 100%;
    height: 40px;
    position: absolute;
    z-index: 20;
    bottom: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}
.body-wrapper .user-profile .profile-image .select-file .camera{
    width: 30px;
    height: 30px;
    color: var(--app-color);
}


.body-wrapper .user-profile .profile-image input{
    width: 80px;
    height: 100%;
    z-index: 8;
    position: absolute;
    border-radius: 50%;
    opacity: 0;
}

.body-wrapper .user-profile .profile-image img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    z-index: 5;
    border-radius: 50%;
}

.body-wrapper .user-profile .name-email{
    display: flex;
    flex-direction: column;
}

.body-wrapper .user-profile .name-email .name{
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}

.body-wrapper .user-profile .name-email .email{
    font-weight: 600;
    font-size: 14px;
    color: #919191;
}

.body-wrapper .profile-info{
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.body-wrapper .profile-info .profile-buttons{
    width: 96%;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    margin-left: 2%;
    margin-bottom: 20px;
    align-items: center;
    color: #000000;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px;
    border-radius: 20px;
}
.body-wrapper .profile-info .profile-buttons div{
    display: flex;
    flex-direction: column;
    gap:2px;
}

.body-wrapper .profile-info .profile-buttons div label:nth-child(1){
    font-size: 18px;
    font-weight: bold;
}

.body-wrapper .profile-info .profile-buttons div label:nth-child(2){
    font-size: 12px;
    font-weight: 600;
    color: #919191;
}
@media(min-width:200px){
    .body-wrapper .profile_bg{
        margin-top: 50px;
        height: 120px;
    }
    .profile-body{
        margin-top:0px;
    }
    .edit-icon{
        width: 20px;
    }
}

@media(min-width:768px){
    .body-wrapper .profile_bg{
        margin-top: 120px;
        height: 200px;
    }
    .profile-body{
        margin-top:130px;
    }
    .edit-icon{
        width: 30px;
    }
}