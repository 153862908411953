.filters{
    width: auto;
    height: fit-content;
    box-shadow: .1px .1px .1px .5px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    min-width: 250px;
}

.filters .filter{
    width: 94%;
    margin: 3%;
}


.filters .filter::after{
    content: "";
    width: 94%;
    margin: 3%;
    display: block;
    height: 1px;
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.18);
}

.filters .filter .heading{
    font-size: var(--default-font-size);
    text-align: center;
    font-weight: 700;
}

.filters .filter .data{
    font-size: var(--small-font-size);
}

.filters .filter .data label{
    font-size: var(--small-font-size);
    font-weight: 400;
}