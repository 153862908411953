.cart-wrapper{
        width: 80%;
        margin: 0%;
        position: relative;
    }
.cart-wrapper .cart{
    width:100%;
    
}
.cart-wrapper .cart .cart-data{
    width: 100%;
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:10;
    position:relative;
}
.cart-wrapper .cart .cart-data .items{
    width: 180%;
    box-shadow: .1px .1px .1px .5px rgba(0, 0, 0, 0.18);
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 5px;
}
.cart-wrapper .cart .cart-data .items .cart-item{
    width: 100%;
    height: 170px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: .5 ease-in-out;
    text-decoration: none;
    color: #000000;
    position: relative;
}

.cart-wrapper .cart .cart-data .items .cart-item:hover{
    box-shadow: .1px .1px .1px .5px rgba(0, 0, 0, 0.18);
}

.cart-wrapper .cart .cart-data .items .cart-item .cart-item-image{
    width: 29%;
    height: 100%;
    border-radius: 5px;
    object-fit: contain;
}

.cart-wrapper .cart .cart-data .items .cart-item .cart-item-image-default{
    width: 29%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.cart-wrapper .cart .cart-data .items .cart-item .cart-item-info{
    width: 69%;
    height: 100%;
    border-radius: 5px;
    position: relative;
}

.cart-wrapper .cart .cart-data .items .cart-item .cart-item-info .cart-item-info-quantity{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 2px;
    
}

.cart-wrapper .cart .total-and-buttons{
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 30px;
    width: 60%;
    height: fit-content !important;
    box-shadow: .1px .1px .1px .5px rgba(0, 0, 0, 0.18);
   
}

.cart-wrapper .cart .total-and-buttons label{
        font-size: 18px;
        font-weight: 700;
    }

@media(min-width:200px){
    .cart-wrapper{
        width: 98%;
        margin-left: 0%;
        margin: 0%;
        margin-top: 0px !important;
    }

    .cart-wrapper .cart .cart-data{
        flex-direction: column;
    }

    .cart-wrapper .cart .cart-data .items{
        width: 100%;
    }

    .cart-wrapper .cart .total-and-buttons{
        flex-direction: column;
        height: auto;
        gap: 10px;
        width: 100%;
    }
    .cart-wrapper .cart .total-and-buttons label{
        width: 90%;
    }
    .cart-wrapper .cart .total-and-buttons div{
        position: relative;
        width: 90%;
    }
}

@media(min-width:768px){
    .cart-wrapper{
        margin-top: 130px !important;
        width: 80%;
        margin: 0%;
    }
    .cart-wrapper .cart .cart-data{
        flex-direction: row;
    }
    .cart-wrapper .cart .cart-data .items{
        width: 180%;
    }
    .cart-wrapper .cart .total-and-buttons{
        flex-direction: column;
        width: 60%;
    }
    .cart-wrapper .cart .total-and-buttons label{
        width: fit-content;
    }
    .cart-wrapper .cart .total-and-buttons div{
        
        width: fit-content;
    }
}