
.addresses-wrapper{
    margin-top: 130px;
    position: relative;
    background-color: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.addresses-wrapper .heading{
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    display: flex;
}
.addresses-wrapper .address-input{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    border: none;
    background-color: #f5f5f5;
    padding-left: 10px;
}
.addresses-wrapper .tabs-btns{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    z-index: 8;
}

/* .addresses-wrapper .map{
    width: 100%;
    height: 500px;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
} */
@media(min-width:200px){
    .addresses-wrapper{
        margin-top: 40px;
        width: 100%;
        padding: 1%;
    }
    .addresses-wrapper .heading{
        display: none;
    }
}

@media(min-width:768px){
    .addresses-wrapper{
        margin-top: 130px;
        width: 80%;
        padding: 0%;
    }
    .addresses-wrapper .heading{
        display: flex;
    }
}