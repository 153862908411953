body {
  margin: 0;
  font-family: "Outfit",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html{
  /* color-scheme: dark light; */
}



:root{
  --50: '#fff8ed';
  --100: '#ffefd5';
  --200: '#fedcaa';
  --300: '#fdc274';
  --400: '#fb9d3c';
  --500: '#f98016';
  --600: '#f3690d';
  --700: '#c24b0c';
  --800: '#9a3c12';
  --900: '#7c3312';
  --950: '#431707';
  --primary-color:#fff8ed;
  --secondary-color:#ffffff;
  --app-color:#f3690d;
  --app-danger:red;
  --app-green:#057205;
  --smallest-font-size:10px;
  --small-font-size:14px;
  --default-font-size:16px;
  --large-font-size:18px;
  --larger-font-size:20px;
}

.body{
    width:100%;
    margin-left: 0%;
    display: flex;
    
    flex-direction: column;
    font-family: "Outfit",sans-serif;
    font-family: Arial, Helvetica, sans-serif;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


.body .body-wrapper{
    width: 90%;
}

input{
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding-left: 10px;

}

input:focus{
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
}

button{
  display: flex;
    justify-content: center;
    align-items: center;
}

@media(min-width:200px){
  .body .body-wrapper{
    width: 100%;
  }
}

@media(min-width:768px){
  .body .body-wrapper{
    width: 90%;
  }
}