.ratings-top-bar{
    width:100%;
    height: 50px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow-x: scroll;
}
.ratings-top-bar::-webkit-scrollbar{
    display: none;
}

.ratings-top-bar .rating-top-bar{
    width: auto;
    border-radius: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    align-items: center;
    flex: 0 0 auto;
}

