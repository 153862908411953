.custom-modal{
    width: 100%;
    position: fixed;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
}

.custom-modal::-webkit-scrollbar{
    display: none;
}


.custom-modal .modal-contents{
    position: absolute;
    background-color: red;
    border-radius: 5px;
    height: 200px;
    width: 300px;
    transition: all .5s ease-in-out;
}

.visible{
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.invisible{
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
}
