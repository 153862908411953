.progress-item{
    width: 33.3%;
    height: 50px;
    display: flex;
    flex-direction: column;
    margin-left: -0.5px;
    flex:0 auto;
}

.progress-item .bar{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.progress-item .bar .horizontal-bar{
    width: 80%;
    height: 5px;
    background-color: #a09d9d;
    flex-grow: 1;
    transition: background-color .5s ease-in-out;
}

.progress-item .round{
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #a09d9d;
    margin-left: -1px;
    transition: background-color .5s ease-in-out;
    display: flex;
    align-items: center;
}

.active{
    background-color: var(--app-green);
}

.progress-item label{
    margin-top: -10px;
    font-size: var(--small-font-size);
}