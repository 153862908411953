.page{
    width: 100%;
    height: 100svh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: background .5s linear;
}

.page .container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
}



.page .container .one{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: red;
    height: 200px;
}

.span{
    margin-top: -100px;
    transition: all .5s ease;
    /* animation:animate .5s linear; */

}

@keyframes animate {
    0%{
        color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        margin-top: -100px;
    }

    100%{
        opacity: 1;
        margin-top: -100px;
        color: rgba(230, 149, 29, 0.6);
    }
}



.page .container .two{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow-x: scroll;
    position: relative;
    
}

.page .container .two::-webkit-scrollbar{
    display: none;
}
.page .container .two img{
    width: 200px;
    height: 300px;
    flex: 0 0 auto;
    object-fit: cover;
    transition: all .5s linear;
}

.page .container .two img::after{
    transform: rotate(20deg);
}

.page .container .two img:hover{
    transform: translateX(10%);
}

.page .container .two button {
    position:fixed;
}

.page .container .two .previous {
    left: 0px;
}

.page .container .two .next {
    right: 0px;
}