.body{
    background-color: #ffffff;
    position: relative;
}
.checkout-body{
    width: 100%;
    background-color: #ffffff;
    position: relative;
    height: 100vh;
    margin-top: 80px;
}
.checkout-body .checkout-wrapper{
    width: 80%;
    margin-top: 100px;
}

.checkout-body .checkout-wrapper .navigation{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    gap: 0px;
    margin-top: 120px;
    margin-bottom: 10px;
    
}

.checkout-body .checkout-wrapper .checkout-holder{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.checkout-body .checkout-wrapper .checkout-holder .div1{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.checkout-body .checkout-wrapper .checkout-holder{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 20px;
}
.checkout-body .checkout-wrapper .checkout-holder .order-now{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.checkout-body .checkout-wrapper .checkout-holder .order-now input{
    height: 40px;
    padding-left: 10px;
}
.checkout-body .checkout-wrapper .checkout-holder .order-now .order-now-buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1%;
}

.address-in-checkout{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2px;
    background-color: #f5f5f5;
    border-radius: 5px;
    margin: 1%;
    margin-bottom: 10px;
    width: 98%;
    box-shadow: .1px .1px .1px .5px rgba(0, 0, 0, 0.18);
}

table{
    width: 100%;
    border-spacing: 10px;
}
table th{
    text-align: left;
}

table .gap{
    width: 20%;
}

.total-and-buttons{
    width: 30%;
    box-shadow: .1px .1px .1px .5px rgba(0, 0, 0, 0.18);
    padding: 5px;
    border-radius: 5px;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 10px;

}
.checkout-body .checkout-holder .btns1{
    display: flex;
}

.checkout-body .btns2{
    position: fixed;
    bottom: 0px;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    padding: 2px;
    height: 50px;
    background-color: #ffffff;
}

.select-payment{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* box-shadow: .1px .1px .1px .5px rgba(0, 0, 0, 0.18); */
    border-radius: 5px;
    padding: 5px;
}

.select-payment div{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-shadow: .1px .1px .1px .5px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    padding: 5px;
    position: relative;
    gap: 20px;
}

.select-payment div img{
    height: 80px;
    width: 80px;
    object-fit: contain;
}

.order-data{
    box-shadow: .1px .1px .1px .5px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    padding: 5px;
}

.select-address{
    width: 100%;
    box-shadow: .1px .1px .1px .5px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    padding: 5px;
}

.select-address::-webkit-scrollbar{
    display: none;
}

.print-order{
    width: 100%;
    height: 100vh;
    margin-top: 110px;
    background-color:#ffffff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0);
    border-radius: 10px;
}

@media(min-width:200px){
    .print-order{
    margin-top: 0px;
    }
    .checkout-body{
        margin-top: 50px;
        width: 100%;
       
    }
    .checkout-body .checkout-wrapper{
        width: 98%;
        margin-left: 0%;
        margin: 0%;
        padding: 2%;
    }
    .checkout-body .checkout-wrapper .checkout-holder{
        flex-direction: column;
    }
    .checkout-body .checkout-wrapper .navigation{
        margin-top: 10px;
    }

    .checkout-body .checkout-wrapper .checkout-holder .div1{
        flex-direction: column;
    }
    
    .total-and-buttons{
        width: 100%;
    }

    .checkout-body .btns2{
        display: flex;
    }
    .checkout-body .checkout-holder .btns1{
        display: none;
    }
}

@media(min-width:768px){
    .print-order{
    margin-top: 110px;
    }
    .checkout-body{
        margin-top: 100px;
        width: 100%;
        padding: 0%;
    }
    .checkout-body .checkout-wrapper{
        width: 80%;
        margin-left: 10%;
         padding: 0%;
    }
    .checkout-body .checkout-wrapper .checkout-holder{
        flex-direction: column;
    }
    .checkout-body .checkout-wrapper .navigation{
        margin-top: 120px;
    }
    .checkout-body .checkout-wrapper .checkout-holder .div1{
        flex-direction: row;
    }
    .total-and-buttons{
        width: 30%;
    }
    .checkout-body .btns2{
        display: none;
    }
    .checkout-body .checkout-holder .btns1{
        display: flex;
    }
}