.banner{
    height: 300px;
    width: auto;
    margin-left: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    overflow-x: auto;
    display: flex;
    scroll-behavior: smooth;
    position: relative;
    scroll-snap-type: x mandatory;
}

.banner::-webkit-scrollbar{
    display: none;
}

.banner .banner-move-button{
    position: absolute !important;
    height: 100% !important;
    width: 50px !important;
    /* background: linear-gradient(90deg,#918b8b,0%,#e0dcdc,100%,#f5f5f5); */
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.banner .banner-prev{
    left:0px !important;
}

.banner .banner-next{
    right:0px;
}

.banner .banner-image-description{
    width: 100%;
    height: 100%;
    overflow-x: auto;
    display: flex;
    scroll-behavior: smooth;
}

.banner .banner-image-description::-webkit-scrollbar{
    display: none;
}

.banner .banner-image-description .banner-image-banner{
    width: 100%;
    height: 100%;
    flex: 0 0 auto;
    position: relative;
    scroll-snap-align: start;
}

.banner .banner-image-description .banner-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    flex: 0 0 auto;
}

.banner .banner-image-description .default-banner-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    flex: 0 0 auto;
    position: absolute;
    display: flex;
    top: 0;
}

@media(min-width:200px){
    .banner{
        width: 100%;
        height: 400px;
        margin-left: 0px;
        margin-top: 0px;
        border-radius: 0px;
    }
    .banner .banner-move-button{
        display: none;
    }
}

@media(min-width:768px){
    .banner{
        width: 100%;
        height: 450px;
        margin-left: 20px;
        border-radius: 5px;
        
    }
    .banner .banner-move-button{
        display: flex;
    }
}

.all{
    background-color: #ffffff;
}
