.body .body-wrapper .btns{
    display: flex;
    flex-direction: row;
    gap:1%;
    margin-top: 100px;
    overflow-x: auto;
    margin-bottom: 20px;
}
.body .body-wrapper .btns::-webkit-scrollbar{
    display: none;
}
.body .body-wrapper .btns .delivery-btn{
    flex: 0 0 auto;
    width: 120px;
}

.body .body-wrapper .deliveries{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
@media(min-width:200px){
    .body .body-wrapper .btns{
        margin-top: 50px;
    }
}

@media(min-width:768px){
    .body .body-wrapper .btns{
        margin-top: 100px;
    }
}