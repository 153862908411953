.add-to-bag-holder{
    height:fit-content;
    background-color: var(--app-color);
    display: flex;
    flex-direction: column;
    gap:18px;
    padding: 5px;
    align-items: center;
    z-index: 110;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

}

.add-to-bag-holder .add-subtract{
    display: none;
    align-items: center;
    gap: 10px;
    color: #ffffff;
    flex-direction: column;
}

.add-to-bag-holder .add-subtract .add-sub{
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: row;
}

.add-to-bag-holder .buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

@media(min-width:200px){
    .add-to-bag-holder{
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 110;
    }
}

@media(min-width:768px){
    .add-to-bag-holder{
        position: relative;
        width: 100%;
        z-index: 1;
    }
}