.body{
    width:100%;
    margin-left: 0%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.body .wrapper{
    width: 90%;
}
.body .body-wrapper .sidebar_and_banner{
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    margin-bottom: 50px;
}

.body .small-screen-banner{
    display: none;
}

@media(min-width:200px){
    .body .header-wrapper{
        display: none;
    }
    .body .body-wrapper .sidebar_and_banner{
        margin: 0px;
        
    }
    .body .small-screen-banner{
        display: flex;
    }

    .body .body-wrapper{
        margin: 0%;
    }
}

@media(min-width:768px){
    .body .header-wrapper{
        display: flex;
    }
    .body .body-wrapper .sidebar_and_banner{
        margin-top: 20px;
        margin-bottom: 50px;
    }
    .body .small-screen-banner{
        display: none;
    }
}
