
.products-wrapper{
    margin-top: 100px;
    position: relative;
    flex: 1;
    background-color: #ffffff;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap:10px;
}

.products-wrapper .buttons{
    width: 100%;
    right:5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: -26px;
    z-index: 100;
    padding-right: 5px;
    margin-bottom: 5px;
}

.products-wrapper .buttons .btns{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.products-wrapper .products{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.products-wrapper .products .products-sort{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    position: relative;
}

.products-wrapper .products .products-sort .button-filter{
    width: auto;
    background-color: var(--app-color);
    border: none;
}

.products-wrapper .products .products-sort .products-sort-by{
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: baseline;
    justify-content: space-between;
    
}

.products-wrapper .products .products-sort .products-sort-by .sort-list{
    display: none;
    flex-direction: column;
    gap: 10px;
    align-items: baseline;
    justify-content: space-between;
    position: absolute;
    bottom: auto;
    top: 100%;
    background-color: #ffffff;
    z-index: 200;
    right: 5px;
    width: fit-content;
    box-shadow: 1px 0px 0px 1px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    padding: 5px;
}

.products-wrapper .products .products-sort .products-sort-by .sort-by-open{
    display: flex;
}

.products-wrapper .products .products-sort .products-sort-by .sort-list .sort-list-item{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.products-wrapper .products .products-list{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
@media(min-width:200px){
    .products-wrapper{
        margin-top: 10px;
        width: 100%;
    }

    .products-wrapper .products .products-sort .button-filter{
        display: flex;
    }
    .products-wrapper .products .filters-holder{
        display: none;
    }
}

@media(min-width:768px){
    .products-wrapper{
        margin-top: 50px;
        width: 80%;
    }
    .products-wrapper .products .products-sort .button-filter{
        display: none;
    }
    .products-wrapper .products .filters-holder{
        display: flex;
    }
}