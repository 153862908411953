.banner-product{
    width: auto !important;
    height: 300px;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    color: #000000;
    transition: box-shadow .5 ease-in-out;
    position: relative;
}

.banner-product:hover{
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.18);
    filter: drop-shadow(1px 1px 1px 1px rgba(0, 0, 0, 0.18));
    
}

.banner-product .banner-image{
    height: 55%;
    width: 100%;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
    border-radius: 5px;
    position: relative;
}

.banner-product .banner-image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.banner-product .banner-image .default{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    position: absolute;
    z-index: 8;
    display:flex;
    top: 0%;
}

.banner-product .description{
    display: grid;
    flex-direction: column;
    position: relative;
    gap: 0px;
}

.banner-product .description .productName{
    font-size: 16px;
    margin-left: 8px;
    margin-top: 0px;
    font-weight: 400;
    line-height: 16px;
}
.banner-product .description .productShop{
    font-size: 11px;
    margin-left: 8px;
    color: #b9b4b4;
}
.banner-product .description .productPrice{
    font-size: 14px;
    margin-left: 8px;
    color: var(--app-color);
}

.banner-product .description .phoneNumber{
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #29eb33;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.banner-product .add-to-cart{
    /* position: absolute; */
    bottom:0px;
    width: 100%;
    display: none;
}

@media(min-width:200px){
    .banner-product{
        width: 49%;
    }
}

@media(min-width:768px){
    .banner-product{
        width: auto !important;
        word-wrap: break-word;
    }
}