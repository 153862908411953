.horizontal-banner-holder{
    height: 270px;
    gap: 80px;
    overflow-x: auto;
    display: flex;
    scroll-behavior: smooth;
    position: relative;
    margin-bottom: 20px;
}
.horizontal-banner-holder::-webkit-scrollbar{
    display: none;
}

.horizontal-banner-holder .move_button{
    position: absolute !important;
    height: 100% !important;
    width: 80px !important;
    /*background: linear-gradient(90deg,#f5f5f5,0%,#ffffff,100%,#f5f5f5);*/
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    z-index: 10;
    transition: .5s ease-in-out;
}



.horizontal-banner-holder .move_button .icon{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: var(--app-color);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.move_button_visible{
    opacity: 1;
}

.move_button_hidden{
    opacity: 1;
}

.horizontal-banner-holder .prev{
    left: 0px !important;
}

.horizontal-banner-holder .next{
    right: 0px !important;
}

.horizontal-banner-holder .banner-container{
    height: 100%;
    gap: 10px;
    overflow-x: auto;
    display: flex;
    scroll-behavior: smooth;
    position: relative;
    
}

.horizontal-banner-holder .banner-container::-webkit-scrollbar{
    display: none;
}

@media(min-width:200px){
    .horizontal-banner-holder .move_button{
        display: none;
    }
}

@media(min-width:768px){
    .horizontal-banner-holder .move_button{
        display: flex;
    }
}