body{
    margin: 0px;
}
.banner{
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    margin: 0px;
    background-color: rgba(0, 0, 0, 0.1);
    background-color: transparent;
    z-index: 300;
    position: fixed;
}

.banner .bannerContent{
    position: absolute;
    bottom: 0px;
    background-color: #030303;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    color: #ffffff;
}

.shown{
    display: flex;
}

.hidden{
    display: none;
}
.banner .bannerContent .text{
    font-family: Arial, Helvetica, sans-serif;
}
.banner .bannerContent .btns{
    display: flex;
    gap: 10px;
}



