.addresses-wrapper {
  margin-top: 130px;
  position: relative;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.addresses-wrapper .heading {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  display: flex;
}
.addresses-wrapper .address-input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  outline: none;
  border: none;
  background-color: #f5f5f5;
  padding-left: 10px;
}
.addresses-wrapper .tabs-btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  z-index: 8;
}

.addresses-wrapper .map {
  width: 100%;
  height: 500px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: fixed;
  left: 0;
  top: 120px;
}
 .location{
  position: fixed;
  top: 120px;
  right: 10px;
  min-height: 30px;
  z-index: 8;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  color: white;
  border-radius: 5px;
  max-width: 50%;
}
@media (min-width: 200px) {
  .addresses-wrapper {
    margin-top: 40px;
    width: 100%;
    padding: 1%;
  }
  .addresses-wrapper .heading {
    display: none;
  }
  .addresses-wrapper .map {
    width: 100%;
    height: 100vh;
    top: 50px;
  }
  .location{
    top: 50px;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .addresses-wrapper {
    margin-top: 150px;
    width: 80%;
    padding: 0%;
  }
  .addresses-wrapper .heading {
    display: flex;
  }
  .addresses-wrapper .map {
    width: 100%;
    height: 500px;
    top: 120px;
  }
  .location{
    top: 120px;
    max-width: 50%;
  }
}
