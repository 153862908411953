/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #fff;
    color: #000; /* Black text */
}

/* Header Styles */
header {
    background-color: #f3690d; /* Orange background */
    color: #fff; /* White text */
    text-align: center;
    padding: 20px 0;
}

header h1 {
    margin: 0;
    font-size: 2.5em;
}

/* Main Content Styles */
.main {
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff; /* White background */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: none; /* Orange border */
    border-radius: 20px;
}

section {
    margin-bottom: 20px;
}

section h2 {
    font-size: 1.8em;
    color: #f3690d; /* Orange heading */
    border-bottom: 2px solid #f3690d; /* Orange border */
    padding-bottom: 5px;
    margin-bottom: 10px;
}

ul, ol {
    padding-left: 20px;
}

ul li, ol li {
    margin: 10px 0;
}

strong {
    color: #f3690d; /* Highlighted text in orange */
}

/* Footer Styles */
footer {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    text-align: center;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
}

footer p {
    margin: 0;
    font-size: 0.9em;
}

@media(min-width:200px){
    header{
        margin-top: 50px;
    }
}

@media(min-width:768px){
    header{
        margin-top: 100px;
    }
}
