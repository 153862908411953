.category-holder{
    width: auto;
    height: 200px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    text-decoration: none;
    color: #000000;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    position: relative;
    margin-bottom: 15px;
}

.category-holder img{
    width: 100%;
    height: 80%;
    object-fit: cover;
    border-radius: 10px;
}

.category-holder .default-category-image{
    width: 100%;
    height: 70%;
    object-fit: cover;
    border-radius: 10px;
    top: 0;
    display: flex;
    z-index: 8;
}

.category-holder label{
    font-size: var(--smallest-font-size);
    font-weight: 600;
    height: 20%;
    width: 100%;
    padding-left: 5px;
}