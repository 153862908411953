.header{
    top:0px;
    z-index: 20;
    height: 120px;
    width:100%;
    background-color: #ffffff;
    position: fixed;
    display: flex;
    flex-direction: column-reverse;
   
    box-shadow: 3px 3px 3px rgba(0,0,0,0.1);
    margin-bottom: 20px;
}

.header .top{
    z-index: 20;
    height: 70%;
    width:100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px 0px 0px rgba(0,0,0,0.1);
}

.header .bottom{
    z-index: 15;
    height: 30%;
    width:100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.header .bottom .items{
    width: 72%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.header .bottom .items .link{
    text-decoration: none;
    font-size: var(--default-font-size);
    color: var(--app-color);
}

.header .logo-and-hamburger{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header .logo-and-hamburger .logo{
    color: #ffffff;
    font-size: 30px;
    text-decoration: none;
}

.header .logo-and-hamburger .logo img{
    width: 50px;
    height: 50px;
}
.header .logo-and-hamburger .hamburger{
    width: 20px;
    height: 20px;
    display: none;
}
.header .search{
    width: 150%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    flex-grow: .5;
    gap: 10px;
}
.header .search .search-input{
    width: 100%;
    height: 40px;
    outline: none;
    border-radius: 5px;
    font-size: var(--default-font-size);
    padding-left: 30px;
    border: none;
     border: 2px solid rgba(0,0,0,0.1);
     transition: border .5s ease-in-out;
}

.header .search .search-btn{
    width: 80px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: var(--app-color);
    color: #ffffff;
    
}

.header .search .search-results{
    position: absolute;
    max-height: 400px;
    min-height: 100px;
    background-color: #ffffff;
    top: 82%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 80;
   
}

.header .search .search-results::-webkit-scrollbar {
    width: 10px;
    height: 50px;
    background-color: rgba(0,0,0,0.1);
}

.header .search .search-input:focus{
    border: 2px solid var(--app-color);
}
.header .search .search-icon{
    position: absolute;
    margin-left: 5px;
}
.header .sell{
    padding: 0px 5px 0px 5px;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--app-color);
    text-decoration: none;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
}

.header .signin-signup{
    text-decoration: none;
    font-size: var(--small-font-size);
    color: #ffffff;
    display: flex;
    position: relative;
    display: flex;
    flex-direction: column;
    color: var(--app-color);
    align-items: center;
}

@media(min-width:200px){
    .header{
        flex-direction: column;
        height: 100px;
        display: none;
    }
    .header .logo-and-hamburger{
        width: 96%;
    }
    .header .logo-and-hamburger .hamburger{
        display: flex;
    }
    .header .search{
        width: 96%;
    }
    .header .sell{
        display: none;
    }
    .header .signin-signup{
        display: none;
    }
}

@media(min-width:768px){
    .header{
        flex-direction: column;
        height: 100px;
        display: flex;
    }
    .header .logo-and-hamburger{
        width: auto;
    }
    .header .logo-and-hamburger .hamburger{
        display: none;
    }
    .header .search{
        width: 40%;
    }
    .header .sell{
        display: none;
    }
    .header .signin-signup{
        display: flex;
    }
}