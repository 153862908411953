.small-screen-search{
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.small-screen-search .search-input{
    width: 98%;
    height: 40px;
    outline: 1px solid var(--app-color);
    border-radius: 5px;
    font-size: 16px;
    padding-left: 30px;
    border: none;
    position: fixed;
    top: 0px;
    z-index: 20;
    margin:1%;
    transition: outline .5s ease-in-out;
}
.small-screen-search .search-input:focus{
    border: 1px solid var(--app-color);
    outline:2px solid var(--app-color);
}
.results{
    position: relative;
    background-color: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    margin-top: 42px;
}

.small-screen-search .search-icon{
    position: fixed;
    left:10px;
    top: 12px;
    z-index: 21;
}