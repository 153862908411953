.address{
    width:100% !important;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    padding: 8px;
    position: relative;
}
.address .one{
    font-size: var(--largest-font-size);
    font-weight: 700;
    color: #000000;
    margin-bottom: 5px;
}

.address .two{
    font-size: var(--small-font-size);
    font-weight: 700;
    color: #919191;
    margin-bottom: 5px;
}
.address div{
    display: flex;
    flex-direction: row;
}

.address .edit-address1{
    position: absolute;
    top: 5px;
    right: 45px;
    color:var(--app-color);
    width: 20px;
    height: 20px;

}

.address .delete-address{
    position: absolute;
    top: 5px;
    right: 5px;
    color:var(--app-color);
    width: 20px;
    height: 20px;

}
