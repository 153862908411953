/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
}

/* Header Styles */
header {
    background-color: var(--app-color);
    color: #fff;
    text-align: center;
    padding: 20px 0;
    margin-top: 100px;
}

header h1 {
    margin: 0;
    font-size: 2.5em;
}

header p {
    margin: 5px 0 0;
    font-size: 1.2em;
}

/* Main Content Styles */
.main_help {
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

section {
    margin-bottom: 20px;
}

section h2 {
    font-size: 1.8em;
    color: var(--app-color);
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

ul {
    list-style-type: none;
    padding-left: 0;
}

ul li {
    margin: 5px 0;
}

ul li .a {
    text-decoration: none;
    color: #007bff;
    transition: color 0.3s ease;
}

ul li a:hover {
    color: #0056b3;
}

/* Footer Styles */
footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
}

footer p {
    margin: 0;
    font-size: 0.9em;
}


@media(min-width:200px){
    header{
        margin-top: 50px;
    }
}

@media(min-width:768px){
    header{
        margin-top: 100px;
    }
}