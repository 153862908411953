.sidebar{
    width: 350px !important;
    height: fit-content;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px #f5f5f5;
    max-height: 450px;
    overflow-y: scroll;
}

.sidebar::-webkit-scrollbar{
   width: 5px;
   background-color: var(--app-color);
   border-radius: 10px;
}

.sidebar .category-link{
    text-decoration: none;
    font-size: 16px;
    color: var(--app-color);
    margin-top: 10px;
    margin-left: 5px;
    font-weight: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sidebar .category-link .star{
    margin-right: 10px;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    object-fit: cover;
}