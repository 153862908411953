.footer{
    top:0px;
    z-index: 0;
    height: fit-content;
    width:100%;
    background-color: #363638;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.footer .links{
    height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #ffffff;
    width: 80%;
}

.footer .links .quick-links{
    display: flex;
    flex-direction: column;
    padding-top: 60px;
}

.footer .links .quick-links h3{
    color: var(--app-color);
}

.footer .links .quick-links .link{
    text-decoration: none;
    color: #ffffff;
    font-size: 17px;
    margin-top: 15px;
}

.footer .links .quick-links .subscribe{
    width: 100%;
    display: flex;
    flex-direction: row;
    height: auto;
}

.footer .links .quick-links .subscribe input{
    width: 70%;
    height: 40px;
    border: none;
    outline: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.footer .links .quick-links .subscribe button{
    width: 30%;
    height: 40px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--app-color);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    color: #ffffff;
}


.footer-link{
    text-decoration: none;
    color: #ffffff;
}



.footer .divider{
    width: 80%;
    margin-top: 15px;
    margin-bottom: 10px;
}

.footer .divider hr{
    width: 100%;
    background-color: #ffffff;
    height: 1px;
    opacity: .8;
}

.footer .social{
    height: fit-content;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #f5f5f5;
}

.footer .social .social-media{
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
}

@media(min-width:200px){
    .footer .links{
        width: 100%;
        flex-direction: column;
    }
    .footer .links .quick-links{
        margin: 1%;
    }
    .footer .social{
        flex-direction: column-reverse;
    }
}

@media(min-width:768px){
    .footer .links{
        width: 80%;
        flex-direction: row;
    }
    .footer .links .quick-links{
        margin: 0%;
    }
    .footer .social{
        flex-direction: row;
    }
}