.header_banner1{
    width: 100%;
    display: flex;
    flex-direction:row;
    gap: 10px;
    
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    padding: 10px;
    overflow-x: scroll;
    flex-wrap: wrap;
    margin-top: 10px;
    /* filter: drop-shadow(10px 10px rgba(0, 0, 0, 0.18)); */
}

.header_banner1::-webkit-scrollbar{
    display: none;
}

.header_banner1 div{
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100px;
    box-shadow: .1px .1px .1px .5px rgba(0, 0, 0, 0.18);
    /* filter: drop-shadow(10px 10px 10px 10px rgba(0, 0, 0, 0.18)); */
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    flex-grow: 1;
}