/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #fff; /* White background */
    color: #000; /* Black text */
    line-height: 1.6;
}

/* Header Styles */
header {
    background-color: #f3690d; /* Orange background */
    color: #fff; /* White text */
    text-align: center;
    padding: 20px 0;
}

header h1 {
    margin: 0;
    font-size: 2.5em;
}

/* Main Content Styles */
main {
    width: 100%;
    margin: 20px auto;
    border-radius: 20px;
    padding: 20px;
    background-color: #fff; /* White background */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: none; /* Orange border */
}

section {
    margin-bottom: 30px;
}

section h2 {
    font-size: 1.8em;
    color: #f3690d; /* Orange heading */
    border-bottom: 2px solid #f3690d; /* Orange border */
    padding-bottom: 5px;
    margin-bottom: 15px;
}

section h3 {
    font-size: 1.5em;
    color: #f3690d; /* Orange heading */
    margin-bottom: 10px;
}

p {
    margin: 10px 0;
}

.a {
    color: #f3690d; /* Orange links */
    text-decoration: none;
}

.a:hover {
    text-decoration: underline;
}

/* Contact Form Styles */
.contact-form {
    margin-top: 20px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

input, textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

input:focus, textarea:focus {
    border-color: #f3690d; /* Orange focus */
    outline: none;
}

button {
    background-color: #f3690d; /* Orange button */
    color: #fff; /* White text */
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

button:hover {
    background-color: #d85d0c; /* Darker orange on hover */
}

/* Footer Styles */
footer {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    text-align: center;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
}

footer p {
    margin: 0;
    font-size: 0.9em;
}
