.holder{
    width: 100vw;
    padding-left: 5px;
}
.category_banner {
  height: 200px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  overflow-x: auto;
  display: flex;
  scroll-behavior: smooth;
  position: relative;
  scroll-snap-type: x mandatory;
  gap: 10px;
}

.category_banner::-webkit-scrollbar {
  display: none;
}

.category_banner .category_link {
  width: 200px;
  height: 200px;
  display: flex;
  overflow-x: auto;
  flex-direction: column;
  flex: 0 0 auto;
  background-color: var(--app-color);
  position: relative;
  border-radius: 20px;
}

.category_banner .category_link .star {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.category_banner .category_link .p {
  position: absolute;
  bottom: 10px;
  width: 90%;
  left: 5%;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
