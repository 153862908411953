.order-details{
    width: 98%;
    margin: 1%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 5px;
    gap: 10px;
    transition: height .5s ease-in-out;
}

.order-details .title{
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.order-details .payment{
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 20px;
    transition: .5s ease-in-out;
    
    border-radius: 5px;
    padding: 5px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0);
}

.order-details .payment .item{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: #000000;
    transition: height .5s ease-in-out;
    
}

.order-details .payment .item img{
    width: 40%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
}

.order-details .payment .item div{
    width: 60%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.order-details .payment .item div label:nth-child(1){
    font-size: 16px;

}
.order-details .payment .item div label:nth-child(2){
    font-size: 16px;

}
.order-details .payment .item div label:nth-child(3){
    font-size: 16px;
    font-weight: bold;
}

@media(min-width:200px){
    .order-details{
        margin-top: 40px;
    }
    .order-details .payment .item img{
        width: 40%;
    }
    .order-details .payment .item div{
        width: 60%;
    }
}

@media(min-width:768px){
    .order-details{
        margin-top: 120px;
    }
    .order-details .payment .item img{
        width: 20%;
    }
    .order-details .payment .item div{
        width: 80%;
    }
}